/* eslint-disable no-underscore-dangle */
import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import { navigate } from 'gatsby';
// import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {
  HeroText,
  // theme as MUITheme,
} from '@cleverrealestate/clever-components';
import {
  styleConfig,
} from '@cleverrealestate/clever-helpers';
import HomePageBg from '../images/img_comps/backgrounds/HomePageBg';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import withMUI from '../utils/MUI.utils';

const useStyles = makeStyles(theme => ({
  homePage: {
    ...theme.mixins.layout.column.base,
    position: 'relative',
    backgroundColor: 'white',
    alignItems: 'center',
  },
  bgPhoto: {
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    '@media screen and (max-width: 1580px)': {
      width: '1580px',
      left: '50%',
      transform: 'translateX(-50%)',
      overflow: 'hidden',
    },
    '& > div': {
      [`@media screen and (max-width: ${styleConfig.media.mobileSmall}px)`]: {
        height: '696px',
      },
    },
  },
  heroElementsWrapper: {
    ...theme.mixins.layout.column.base,
    ...theme.mixins.topBarAlign,
    height: 'calc((765/1920 * 100vw) - 200px)',
    position: 'relative',
    alignItems: 'center',
    marginTop: '200px',
    maxWidth: '100%',
    '@media screen and (max-width: 1580px)': {
      height: 'calc((765/1920 * 1580px) - 200px)',
    },
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      ...theme.mixins.topBarAlignMobile,
      ...theme.mixins.layout.column.base,
      marginTop: '100px',
      height: 'calc((765/1920 * 1580px) - 100px) !important',
    },
    [`@media screen and (max-width: ${styleConfig.media.mobileSmall}px)`]: {
      ...theme.mixins.topBarAlignMobile,
      ...theme.mixins.layout.column.base,
      marginTop: '0px',
      marginBottom: '65px',
      justifyContent: 'center',
      height: 'calc(765/1920 * 1580px) !important',
    },
  },
  backButton: {
    ...theme.mixins.color.buttonGradient,
    color: 'white',
    marginTop: '25px',
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();

  const back = () => {
    navigate('/');
  };

  return (
    <Layout
      colorTransitionStart={50}
      shadowTransitionStart={50}
      colorTransitionLength={125}
      shadowTransitionLength={125}
    >
      <SEO
        pathname="/404/"
        type="website"
        title="Uh Oh! 404"
        description="404"
      />
      <div className={classes.homePage}>
        <div className={classes.bgPhoto}>
          <HomePageBg />
        </div>
        <div className={classes.heroElementsWrapper}>
          <HeroText
            instanceKey="top"
            heroText="Uh oh! Looks like that page doesn't exist."
            subText={(
              <Button
                className={classes.backButton}
                color="inherit"
                variant="contained"
                onClick={back}
                aria-label="contact us button"
              >
                Back To Homepage
              </Button>
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

// const WrappedNotFoundPage = () => (
//   <ThemeProvider theme={MUITheme}>
//     <NotFoundPage />
//   </ThemeProvider>
// );

export default withMUI(NotFoundPage);
